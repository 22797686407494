import { axios,axiosMocking } from '../http-common';

class Common {
  paymentAccount(){
    return axios.get("v1/member/reference/payment-account");
  }
  transaction(data){
    if(process.env.VUE_APP_ENVIRONMENT == "local"){
      return axiosMocking.get("index.php",{params:{data:"transaction"}});
    }
    else{
      return axios.get("v1/member/transaction/cardtransaction",{params:data});
    }
  }
  profile(){
  	return axios.get("v1/member/profile");
  }
  kyc(data){
  	return axios.post("v1/member/profile/kyc",data);
  }
  newKyc(data){
    return axios.post("v1/member/profile/kycnew",data);
  }
  getKyc(){
    return axios.get("v1/member/profile/member-kyc");
  }
  job(){
    return axios.get("v1/member/job");
  }
  annual_income(){
    return axios.get("v1/member/annual-income-range");
  }
  update_profile(data){
    return axios.post("v1/member/profile/update-profile-tab",{"UpdateProfileTab":data});
  }

  card_list(data){
    return axios.get("v1/member/card-request",{params:data});
  }

  card_active(){
    return axios.get("v1/member/topup/card-list");
  }

  card_list_utgl(data){
    return axios.get("v1/member/transaction/cardlist",{params:data});
  }

  card_req(data){
    return axios.post("v1/member/card-request",{CardRequest:data});
  }

  card_req_family(data){
    return axios.post("v1/member/family/add",data);
  }

  card_req_new(data,file){
    const params = (file)?{CardRequest:data,CardRequestFile:file}:{CardRequest:data};
    return axios.post("v1/member/card-request/new",params);
  }

  card_unavailable(){
    return axios.get("v1/member/card/currency-unavailable");
  }

  request_cancel_card(data){
    return axios.post("v1/member/chat/request-cancel-card",data);
  }

  activate(data){
    return axios.post("v1/member/card/activate",data);
  }

  request_lock_unlock(data){
    if(data.status==1){
      return axios.post("v1/member/card/lock",{card_number:data.card_number});
    }
    else{
      return axios.post("v1/member/card/un-lock",{card_number:data.card_number});
    }
  }
  change_atm_pin(data){
    return axios.post("v1/member/card/change-atm-pin",data);
  }

  cancel_reason(){
    return axios.get("v1/member/reference/cancel-reason");
  }

  countries(){
    return axios.get("v1/member/reference/countries");
  }
  states(data){
    return axios.get("v1/member/reference/states",{params:data});
  }
  cities(data){
    return axios.get("v1/member/reference/cities",{params:data});
  }

  get_fee(){
    return axios.get("v1/member/reference/provider-fee");
  }
  
  get_language(){
    return axios.get("v1/member/reference/lang");
  }
  set_language(data){
    return axios.post("v1/member/profile/update-language",{"UpdateProfileTab":data});
  }

  // begin::FAQs
  faqs(data){
    return axios.get("v1/member/faq",{params:data});
  }
  tags(){
    return axios.get("v1/member/faq/tags");
  }
  // end::FAQs
}

export default new Common();