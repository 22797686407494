<!-- Chat.vue -->
<template>
    <div class="chat-container" :class="(!isFloating)?'min-vh-100':''">
        <div class="chat-header">
            <div class="fw-700 fz-20">{{ $t('Customer Service') }}</div>
            <div class="fw-400 fz-14">{{ $t('Hello there, we are glad to help you') }}</div>
        </div>
        
        <div v-if="!isMaintenance || isMaintenance != 1" class="category-tabs d-flex w-100">
            <div class="w-100 m-0 text-center" :class="nav=='category'?'active':''" @click="changeNav('category')">
                {{ $t('Category') }}
            </div>
            <div class="w-100 m-0 text-center" :class="nav=='history'?'active':''" @click="changeNav('history')">
                {{ $t('History') }}
            </div>
        </div>

        <Maintenance v-if="isMaintenance == 1"></Maintenance>

        <chat-body 
            ref="chat-body" 
            v-else
            :isFloating="isFloating"
            @is-connect="isConnect"
            @push-chat-list="pushChatList"
            @show-loader="showLoader"
            @set-category="setCategory"
            @chat-list="setChatList"
        ></chat-body>

    </div>
</template>
  
<script>
import ChatBody from '@/components/Chat/ChatBody';
import Maintenance from '@/components/Maintenance';
export default{
    data(){
        return{
            nav : "category",
            isMaintenance:process.env.VUE_APP_MAINTENANCE
        }
    },
    props:['isFloating'],
    components:{
        ChatBody,
        Maintenance
    },
    methods:{
        isConnect(bool){
            this.$emit('is-connect',bool);
        },
        captureFile(event){
            this.$refs['chat-body'].captureFile(event);
        },
        sendMessage(message){
            console.log("prepare to send : "+message);
            this.$refs['chat-body'].sendMessage(message);
        },
        setCategory(category){
            this.$emit('set-category',category);
        },
        changeNav(nav){
            this.nav = nav;
            this.$refs['chat-body'].changeNav(nav);
        },
        setChatList(data){
            this.$emit('chat-list',data);
        },
        showLoader(param){
            this.$emit('show-loader',param);
        },
        pushChatList(param){
            this.$emit('push-chat-list',param);
        }
    }
}
</script>
  <style scoped src="@/assets/css/chat.css"></style>
  