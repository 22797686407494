<template>
  <router-view :key="version"></router-view>
</template>

<script>
export default {
  data(){
    return{
      version : process.env.VUE_APP_NOCACHE_VERSION
    }
  },
  mounted(){
    const language = localStorage.getItem('lang');
    this.$i18n.locale = language?language:'en-EN';
  }
}

</script>
