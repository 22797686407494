<template>
    <div>
        <div class="mb-4 d-flex" v-for="(item,index) in messages" :class="item.user_type">
            <img  v-if="item.user_type=='admin'" :src="`${publicPath}/img/rcp/icon/cs-profile.png`" class="img-circle img-thumbnail p-0 avatar mr-2" alt="avatar">
            <div class="message w-75 fz-14" :class="item.user_type=='admin'?'mr-auto bg-upload-payment':'ml-auto'">
                <img v-if="isImage(item.message)" :src="item.message"/>
                <a v-else-if=isFile(item.message) :href="item.message" target="_blank" class="d-flex align-items-center">
                    <img  :src="`${publicPath}/img/rcp/icon/file-download.png`" width="35px">
                    <div class="d-flex flex-column w-100 px-2">
                        <span class="fz-12 fw-500 text-grey3">{{ item.message.split(/(\\|\/)/g).pop() }}</span>
                        <span class="fz-10 fw-500 text-grey4">{{ formatBytes(item.info.fileSize) }}</span>
                    </div>
                    <img  :src="`${publicPath}/img/rcp/icon/download2.png`" width="34px" class="ml-auto">
                </a>
                <span v-else v-html="item.message"></span>
            </div>
            <img v-if="item.user_type=='member'" src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="img-circle img-thumbnail p-0 avatar ml-2" alt="avatar">
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            publicPath      :process.env.BASE_URL!='/'?process.env.BASE_URL:'',
            messages        :[]
        }
    },
    props:['list'],
    methods:{
        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'

            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB']

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        },
        isFile(message){
            return message && (message.indexOf("pdf")!=-1)?true:false;
        },
        isImage(message){
           return message && (message.indexOf("jpg")!=-1 || message.indexOf("jpeg")!=-1 || message.indexOf("png")!=-1 || message.indexOf("gif")!=-1)?true:false;
        }
    },
    mounted:function(){
        this.messages = this.list;
        console.log("======================");
        console.log(this.messages);
    }
}
</script>

<style scoped src="@/assets/css/chat.css"></style>
