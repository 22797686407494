<template>
    <input
      id="file-input"
      class="hide-file-input"
      type="file"
      accept="image/*"
      @change="onFileChange($event)"
    />
</template>
<script>
const action = {
  valdateImage(file,allowedTypes){
    
    if(!allowedTypes){
      allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    }

    if (!file) {
      return "Please select an image file"; 
    }
    else if (!allowedTypes.includes(file.type)) {
      let typeAllow = "";
      for(let i=0;i<allowedTypes.length;i++){
        let tmp = allowedTypes[i].split("/");
        typeAllow+=tmp[tmp.length-1];

        if(i<allowedTypes.length-2){
          typeAllow+=", ";
        }
        else if(i==allowedTypes.length-2){
          typeAllow+=" and ";
        }
      }
      
      return "Invalid file type. Only "+typeAllow+" are allowed."; 
    }
    else if (file.size > 8000000) {
        return  "File size too large. Maximum size is 8MB.";
    }
    else{
      return "";
    }
  }
}

export default {
  props:['allowedTypes'],
  methods: {
    onFileChange($event) {
      const file = $event.target.files[0]
      const reader = new FileReader();
      const validate = action.valdateImage(file,this.allowedTypes);
      if (validate == "") {
        reader.readAsDataURL(file)
        reader.onload = () => {
          // Set a new property on the captured `file` and set it to the converted base64 image
          file.previewBase64 = reader.result
          // Emit the file with the new previewBase64 property on it
          this.$emit('file-updated', file)
        }
        reader.onerror = (error) => {
          
        }
      }
      else{
        console.log(validate);
        this.$notify({
          type: 'warn',
          text: validate
        })
      }
    }
  }
}
</script>