<template>
    <div class="h-100 overflow-hidden">
        <div class="d-flex flex-column h-100 overflow-auto">
            <div class="chat-body h-100">
                <div v-if="isLoader" class="d-flex align-items-center justify-content-center h-100">
                    <div class="dot-falling"></div>
                </div>
                <div :class="!isFloating?'h-100':''" v-else>

                    <!-- Jika Navigasinya category -->
                    <div v-if="nav == 'category'" :class="(isChatting?'d-none d-md-block':'')+' '+(!isFloating?'h-100':'')">
                        <div :class="'mb-4 '+(!currentCategory?'d-block':(isFloating?'d-none':'d-none d-md-block'))">
                            <div class="d-flex w-100 btn-category mb-2" @click="chooseCategory(item.id)" v-for="item in categories" :key="item.id">
                                <img :src=item.icon />
                                <span class="m-2">{{ $t(item.category) }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Jika Navigasinya History -->
                    <div v-else-if="nav == 'history'" class="w-100" :class="(isChatting?(!isFloating?'d-none d-md-block':'d-none'):'')+' '+(!isFloating?'h-100':'')">
                        <div v-if="history && history.length>0">
                            <latest-conversation-block v-for="item in history" :data="item" @goto-chat="gotoChat" :key="item.id"></latest-conversation-block>
                        </div>
                        <div v-else>
                            <img :src="`${publicPath}/img/rcp/empty-message.png`" class="w-100 pl-5 pr-5 pt-2 pb-2"/>
                            <div class="text-center fw-400 fz-14 caption">{{ $t("You haven't asked something, create your question and this live chat is ready to help answer your question quickly and accurately.") }}</div>
                        </div>
                    </div>

                    <div v-if="isChatting" :class="!isFloating?'h-100':''">
                        <chat-list ref="chatList" :list="messages"></chat-list>
                    </div>

                </div>
                
            </div>

            <chat-input 
                :class="(isFloating?'d-block':'d-block d-md-none')"
                v-if="isChatting && currentCategory!='' && (messages.length==0 || (messages.length>0 && messages[0].status && messages[0].status==1))"
                ref="chatInput2"
                @capture-file="captureFile" 
                @send-message="sendMessage"
            ></chat-input>

            <div :class="(!isFloating?'d-block':'d-none d-md-block')" v-if="!isChatting" class="chat-input text-center p-2 pt-3 pb-3">
                <span v-if="nav=='category'">{{ $t('Select a category to start the conversation') }}</span>
                <span v-else>{{ $t('See all chat history here') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
  import ChatInput from "@/components/Chat/ChatInput";
  import ChatList from "@/components/Chat/ChatList";
  import LatestConversationBlock from '@/components/Chat/LatestConversationBlock';

  const CHECK_CLIENT_STATE = "client";
  const GET_CATEGORY_STATE = "get_category";
  const GET_HISTORY_STATE = "history";
  const GET_LATEST_STATE = "latest_chat";
  const GET_CHAT_LIST = "chat_list";
  const CHATTING = "chatting";

  export default {
    data() {
      return {
        publicPath      :process.env.BASE_URL!='/'?process.env.BASE_URL:'',
        socketURL 	    :process.env.VUE_APP_CHAT_SOCKET_URL,
        clientName      :process.env.VUE_APP_CLIENT_NAME,
        messages        : [],
        isLoader        :true,
        isConnect       :false,
        connection      :null,
        isChatting      :false,
        nav             :"category",
        state           : '',
        categories      : [],
        currentCategory : '',
        currentRoomId   : '',
        history         : []
      };
    },
    components:{
        ChatInput,
        ChatList,
        LatestConversationBlock
    },
    props:['isFloating'],
    methods: {
        changeNav(nav){
            this.isChatting = false;
            this.messages = [];
            this.currentCategory = "";
            this.$emit('set-category',this.currentCategory);
            this.nav = nav;

            if(this.nav == "history"){
                this.getHistory();
            }
            else{
                this.getCategory();
            }
        },
        captureFile($event){
            this.state = CHATTING;
            const id = localStorage.getItem('member');
            const language = localStorage.getItem('lang-key');
            this.send(JSON.stringify([this.state, id, "", btoa(this.currentRoomId), btoa(this.currentCategory), [$event.previewBase64,language]]));
        },
        toggleChat() {
            this.$emit('toggle-chat');
        },
        setCategory(id){
            this.nav = "category";
            this.chooseCategory(id);
        },
        gotoChat(params){
            if(this.isFloating){
                this.isLoader = true;
            }
            this.isChatting = true;
            this.$emit('show-loader',true);

            this.state = GET_CHAT_LIST;
            // this.nav = "category";
            
            this.currentCategory = params['category_id'];
            this.currentRoomId = params['room_id'];

            this.$emit('set-category',this.currentCategory);

            const id = localStorage.getItem('member');
            this.send(JSON.stringify([this.state, id, '', btoa(params['room_id'])]));
        },
        chooseCategory(category_id) {
            this.isChatting = true;
            this.currentCategory = category_id;
            this.$emit('set-category',this.currentCategory);
            this.getLatest(category_id);
        },
        sendMessage(message) {
            if(message){
                this.$emit('show-loader',true);
                this.state = CHATTING;
                const id = localStorage.getItem('member');
                const language = localStorage.getItem('lang-key');
                console.log("==== CHATTING =====");
                console.log(JSON.stringify([this.state, id, "", btoa(this.currentRoomId), btoa(this.currentCategory), [message,language]]));
                this.send(JSON.stringify([this.state, id, "", btoa(this.currentRoomId), btoa(this.currentCategory), [message,language]]));
            }
        },
        returnMessage(param){
            if(param.message){
                this.$emit('show-loader',false);
                if(this.isFloating){
                    this.messages.push({
                        "status":param.status,
                        "user_type":param.user_type,
                        "message":param.message,
                        "info":param.info
                    });
                }
                else{
                    this.$emit('push-chat-list',param);
                }
            }
        },
        getCategory(){
            this.state = GET_CATEGORY_STATE;
            const id = localStorage.getItem('member');
            this.send(JSON.stringify([this.state, id]));
        },
        getLatest(category_id){
            if(this.isFloating){
                this.isLoader = true;
            }
            this.$emit('show-loader',true);
            this.currentRoomId = '';
            this.state = GET_LATEST_STATE;
            const id = localStorage.getItem('member');
            console.log("latest room");
            console.log(JSON.stringify(["room_list", id, "", [0,1,1,category_id]]));
            this.send(JSON.stringify(["room_list", id, "", [0,1,1,category_id]]));
        },
        getHistory(){
            this.state = GET_HISTORY_STATE;
            const id = localStorage.getItem('member');
            this.send(JSON.stringify(["room_list", id]));
        },
        getChatList(){
            this.state = GET_CHAT_LIST;
            const id = localStorage.getItem('member');
            console.log(JSON.stringify([this.state, id, '', btoa(this.currentRoomId)]));
            this.send(JSON.stringify([this.state, id, '', btoa(this.currentRoomId)]));
        },
        setListMessage(list){
            this.messages =  list;
            if(this.$refs['chatList']){
                this.$refs['chatList'].messages=list;
            }
            this.$emit('chat-list',list);
        },
        send(data){
            this.connection.send(data);
        },
        connect(){
            const self = this;

            self.isConnect = false;
            self.$emit('is-connect',self.isConnect);

            this.connection = new WebSocket(this.socketURL+"?client="+self.clientName);

            this.connection.onopen = function() {
                self.isConnect = true;
                self.$emit('is-connect',self.isConnect);
                self.getCategory();
            };
            
            this.connection.onclose = function (event) {
                self.connect();
            };
            
            this.connection.onmessage = function(event) {
                const ret = JSON.parse(event.data);
                console.log("===== "+self.state+" =====");
                console.log(ret);
                switch(self.state){
                    case GET_CATEGORY_STATE:
                        self.isLoader = false;
                        self.$emit('show-loader',self.isLoader);
                        self.categories = ret;
                        break;
                    case GET_HISTORY_STATE:
                        self.history = ret.data;
                        break;
                    case GET_LATEST_STATE:
                        if(ret.data && ret.data.length>0){
                            self.currentRoomId = ret.data[0].room_id;
                        }
                        else{
                            self.isLoader = false;
                            self.$emit('show-loader',self.isLoader);
                            self.$emit('chat-list',[]);
                        }
                        self.getChatList();
                        break;
                    case GET_CHAT_LIST:
                        self.isLoader = false;
                        self.$emit('show-loader',self.isLoader);
                        self.state = CHATTING;
                        self.setListMessage(ret.data);
                        break;
                    case CHATTING:
                        self.currentRoomId = parseInt(ret.room_id);
                        self.returnMessage(ret);
                        break;
                }
            };

            this.connection.onerror = function(error) {
            };
        }
    },
    mounted: function() {
        this.connect();

    }
  };
  </script>

<style scoped src="@/assets/css/chat.css"></style>