<template>
    <div class="chat-input text-center w-100">
        <div class="d-flex align-items-center p-2">
            <button class="mr-1 attach">
                <img :src="`${publicPath}/img/rcp/icon/attach.png`"/>
                <FileComponent @file-updated="captureFile($event)" :key="'file'"></FileComponent>
            </button>
            <textarea v-model="newMessage" @keydown.enter.prevent="sendMessage" :placeholder="`${$t('Write text here')}...`"></textarea>
            <button @click="sendMessage" class="btn bg-upload-payment">
                <img :src="`${publicPath}/img/rcp/icon/cs-send.png`"/>
            </button>
        </div>
    </div>
</template>

<script>
import FileComponent from "@/components/FileComponent";

export default{
    data(){
        return{
            publicPath      :process.env.BASE_URL!='/'?process.env.BASE_URL:'',
            newMessage      :''
        }
    },
    components:{
        FileComponent
    },
    methods:{
        captureFile($event){
            this.$emit('capture-file',$event);
        },
        sendMessage(){
            if(this.newMessage){
                this.$emit('send-message',this.newMessage);
                this.newMessage = "";
            }
        }
    },
    mounted:function(){
        this.message = '';
    }
}
</script>

<style scoped src="@/assets/css/chat.css"></style>